import { configureStore, combineReducers } from "@reduxjs/toolkit";
import productsSlice from "./slices";

const rootReducer = combineReducers({
  productsReducer: productsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

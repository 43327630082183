import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { theme } from "theme";

import { Start } from "./pages/Start";
import { Categories } from "./pages/Categories";
import { Product } from "./pages/Product";
import { PurchasedGoods } from "./pages/PurchasedGoods";
import { ForWeighing } from "./pages/ForWeighing";
import { Category } from "./pages/Category";
import { store } from "./reducer";

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <Routes>
              <Route path="/">
                <Route index element={<Start />} />
                <Route path="categories">
                  <Route index element={<Categories />} />
                  <Route path=":categoryId">
                    <Route index element={<Category />} />
                    <Route path=":productId" element={<Product />} />
                  </Route>
                </Route>
                <Route path="for-weighing" element={<ForWeighing />} />
                <Route path="purchased-goods" element={<PurchasedGoods />} />
              </Route>
            </Routes>
          </StyledEngineProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "reducer";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { TypographyHeadlines } from "../components/TypographyHeadlines";

export const PurchasedGoods: React.FC = () => {
  const purchasedGoods = useSelector((state: RootState) => {
    return state.productsReducer.products.filter((product) => (product.weight ?? product.quantity) > 0);
  });

  const purchasedGoodsSum = purchasedGoods.reduce((sum: number, product) => {
    return sum + product.price * (product.weight ?? product.quantity);
  }, 0);

  const navigate = useNavigate();

  const goToCategories = () => {
    navigate("/categories");
  };

  return (
    <Container maxWidth="xs">
      <Box height="90%" sx={{ overflowY: "auto" }}>
        <Stack>
          <TypographyHeadlines sx={{ fontSize: "48px", marginTop: 0 }} variant="h2">
            КУПЛЕННЫЕ ПРОДУКТЫ
          </TypographyHeadlines>
          <Divider sx={{ borderColor: "#9e9e9e" }} />
          <Typography sx={{ fontSize: "24px", fontWeight: "400" }}>
            {purchasedGoods.map((product) => (
              <Box key={product.id}>{`${product.name}: ${(product.price * (product.weight ?? product.quantity)).toFixed(2)} руб.`}</Box>
            ))}

            <Divider sx={{ borderColor: "#9e9e9e" }} />
            <Typography sx={{ fontSize: "32px", fontWeight: "400" }}>Итого: {`${purchasedGoodsSum.toFixed(2)} руб.`}</Typography>
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ paddingTop: "5px" }}>
        <Stack>
          <Button onClick={goToCategories}>НАЗАД</Button>
        </Stack>
      </Box>
    </Container>
  );
};

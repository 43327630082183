import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { ClearButton } from "components/ClearButton";
import { RootState } from "reducer";

import { clearProductProperties } from "../slices";

export const Category: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { categoryId } = useParams(); // "vegetables"

  const products = useSelector((state: RootState) => state.productsReducer.products); // здесь получили массив со всеми продуктами

  const productsInCategory = products.filter((product) => product.category.path === categoryId); // здесь мы получили массив только с vegetables

  const goToProduct = (prodictId: string) => {
    navigate(`/categories/${categoryId}/${prodictId}`);
  };

  const goToCategories = () => {
    navigate("/categories");
  };

  const clearProduct = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, productId: string) => {
    event.stopPropagation();
    dispatch(clearProductProperties({ id: productId }));
  };

  return (
    <Container maxWidth="xs">
      <Box height="90%" sx={{ overflowY: "auto" }}>
        <Stack spacing={1}>
          {productsInCategory.map((product) => {
            const productCost = product.price * (product.weight ?? product.quantity);
            return (
              <Box key={product.id}>
                {product.price === 0 ? (
                  <Stack>
                    <Button onClick={() => goToProduct(product.id)}>{product.name}</Button>
                  </Stack>
                ) : (
                  <Stack>
                    <Button sx={{ display: "flex", alignItems: "flex-start" }} onClick={() => goToProduct(product.id)}>
                      <Box sx={{ display: "flex", flexDirection: "column", minWidth: "77%" }}>
                        <List sx={{ fontSize: "28px", color: "white", alignSelf: "center", paddingBottom: "1px" }}>{`${product.name}: `}</List>
                        <List sx={{ fontSize: "24px", color: "white", alignSelf: "center", padding: "0 0 0 0" }}>{`${productCost.toFixed(
                          2
                        )} руб.`}</List>
                        <Stack sx={{ fontSize: "16px", borderRadius: "8px", margin: "0 0 0 5px", maxWidth: "95%" }}>
                          {`цена: ${product.price} руб.`}
                          <Divider />
                          {product.weight !== undefined ? `вес: ${product.weight} кг.` : `кол-во: ${product.quantity} шт.`}
                        </Stack>
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ClearButton onClick={(event) => clearProduct(event, product.id)}>Clear</ClearButton>
                      </Box>
                    </Button>
                  </Stack>
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>
      <Box sx={{ paddingTop: "5px" }}>
        <Stack>
          <Button onClick={goToCategories}>НАЗАД</Button>
        </Stack>
      </Box>
    </Container>
  );
};

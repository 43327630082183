import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "reducer";
import { ProductType } from "types/ProductType";
import { clearProductProperties } from "../slices";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ClearButton } from "components/ClearButton";

export const ForWeighing: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const goToCategories = () => {
    navigate("/categories");
  };

  const clearProduct = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, productId: string) => {
    event.stopPropagation();
    dispatch(clearProductProperties({ id: productId }));
  };

  const goodsForWeighing = useSelector((state: RootState) => {
    return state.productsReducer.products.filter((product) => product.price > 0 && (product.weight ?? product.quantity) === 0);
  });

  const goToHandler = (product: ProductType) => {
    navigate(`/categories/${product.category.path}/${product.id}?redirectedFrom=weighing`);
  };

  return (
    <Container maxWidth="xs">
      <Box height="90%" sx={{ overflowY: "auto" }}>
        <Stack spacing={1}>
          {goodsForWeighing.map(
            (product) =>
              product.price > 0 && (
                <Stack>
                  <Button key={product.id} sx={{ display: "flex", alignItems: "flex-start" }} onClick={() => goToHandler(product)}>
                    <Box sx={{ display: "flex", flexDirection: "column", minWidth: "77%" }}>
                      <List sx={{ fontSize: "28px", color: "white", alignSelf: "center", paddingBottom: "1px" }}>{`${product.name}: `}</List>
                      <List sx={{ fontSize: "24px", color: "white", alignSelf: "center", padding: "0 0 0 0" }}>{`${(
                        product.price * (product.weight ?? product.quantity)
                      ).toFixed(2)} руб.`}</List>
                      <Stack sx={{ fontSize: "16px", borderRadius: "8px", margin: "0 0 0 5px", maxWidth: "95%" }}>
                        {`цена: ${product.price} руб.`}
                        <Divider />
                        {product.weight !== undefined ? `вес: ${product.weight} кг.` : `кол-во: ${product.quantity} шт.`}
                      </Stack>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <ClearButton onClick={(event) => clearProduct(event, product.id)}>Clear</ClearButton>
                    </Box>
                  </Button>
                </Stack>
              )
          )}
        </Stack>
      </Box>

      <Box sx={{ paddingTop: "5px" }}>
        <Stack>
          <Button onClick={goToCategories}>НАЗАД</Button>
        </Stack>
      </Box>
    </Container>
  );
};

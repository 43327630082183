import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { RootState } from "reducer";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { setPrice, setWeight, setQuantity } from "../slices";
import { Keyboard } from "../components/Keyboard";

export const Product: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { productId, categoryId } = useParams(); // берём из URL

  const [params] = useSearchParams();

  const product = useSelector((state: RootState) => {
    return state.productsReducer.products.find((product) => product.id === productId);
  });

  const goodsForWeighing = useSelector((state: RootState) => {
    return state.productsReducer.products.filter((product) => product.price > 0 && (product.weight ?? product.quantity) === 0);
  });

  const goToCategory = () => {
    navigate(`/categories/${categoryId}`);
  };

  const goToWeighing = () => {
    navigate("/for-weighing");
  };

  const goToPurchasedGoods = () => {
    navigate("/purchased-goods");
  };

  const goBack = () => {
    if (params.get("redirectedFrom") === "weighing") {
      if (goodsForWeighing.filter((productForWeighing) => product !== undefined && productForWeighing.id !== product.id).length > 0) {
        goToWeighing();
      } else {
        goToPurchasedGoods();
      }
    } else {
      goToCategory();
    }
  };

  const onSetPrice = (price: number) => {
    /*
    setPrice: (state, action) => {
      const targetProduct = state.products.find((el) => el.id === action.payload.id);
      targetProduct.price = action.payload.price;
      state.products = state.products.map((product) => {
        return product.id === targetProduct.id ? targetProduct : product;
      });
    },
    */
    dispatch(setPrice({ id: product?.id, price: price }));
    // если product не равен null или undefined то тогда возьми поле id
    // в противном случае вся конструкция (product?.id) равна undefined тоже самое что и {id: product !== undefined && product.id}
    /*
      Получается что в нашем примере внутри dispatch запускается функция setPrice.
      setPrice в качестве аргумента принимает объект { id: product.id, price: price }
      который мы потом можем получить как action.payload
    */

    goToCategory();
  };

  const onSetWeight = (weight: number) => {
    dispatch(setWeight({ id: product?.id, weight: weight }));
    goBack();
  };

  const onSetQuantity = (quantity: number) => {
    dispatch(setQuantity({ id: product?.id, quantity: quantity }));
    goBack();
  };

  if (product === undefined) {
    return null;
  }

  return (
    <Container maxWidth="xs">
      <Box height="90%" sx={{ overflowY: "auto" }}>
        <Typography sx={{ fontSize: "52px", fontWeight: "500" }}>{product.name}</Typography>
        {product.price === 0 ? (
          <>
            <Typography sx={{ fontSize: "48px", fontWeight: "400" }}>{`цена: ${product.price} руб.`}</Typography>
            <Keyboard onOkClick={onSetPrice} initialValue={product.price} />
          </>
        ) : product.weight !== undefined ? (
          <>
            <Typography sx={{ fontSize: "48px", fontWeight: "400" }}>{`вес: ${product.weight} кг.`}</Typography>
            <Keyboard onOkClick={onSetWeight} initialValue={product.weight} />
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: "48px", fontWeight: "400" }}>{`кол-во: ${product !== undefined && product.quantity} шт.`}</Typography>
            <Keyboard onOkClick={onSetQuantity} initialValue={product.quantity} />
          </>
        )}
      </Box>
      <Box>
        <Stack>
          <Button onClick={goBack}>НАЗАД</Button>
        </Stack>
      </Box>
    </Container>
  );
};

import { createSlice } from "@reduxjs/toolkit";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [
      {
        id: "potato",
        name: "КАРТОФЕЛЬ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "tomato",
        name: "ПОМИДОРЫ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "cucumber",
        name: "ОГУРЦЫ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "cabbage",
        name: "КАПУСТА",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "onion",
        name: "ЛУК",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "carrot",
        name: "МОРКОВЬ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "beet",
        name: "СВЁКЛА",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "paprika",
        name: "СЛАДКИЙ ПЕРЕЦ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "garlic",
        name: "ЧЕСНОК",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "vegetables",
        },
      },
      {
        id: "apple",
        name: "ЯБЛОКИ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "fruits",
        },
      },
      {
        id: "banana",
        name: "БАНАНЫ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "fruits",
        },
      },
      {
        id: "pear",
        name: "ГРУШИ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "fruits",
        },
      },
      {
        id: "orange",
        name: "АПЕЛЬСИНЫ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "fruits",
        },
      },
      {
        id: "lemon",
        name: "ЛИМОНЫ",
        price: 0,
        weight: 0.0,
        imgURL: "",
        category: {
          path: "fruits",
        },
      },
      {
        id: "piece-goods-1",
        name: "ТОВАР 1",
        price: 0,
        quantity: 0,
        imgURL: "",
        category: {
          path: "piece-goods",
        },
      },
      {
        id: "piece-goods-2",
        name: "ТОВАР 2",
        price: 0,
        quantity: 0,
        imgURL: "",
        category: {
          path: "piece-goods",
        },
      },
      {
        id: "piece-goods-3",
        name: "ТОВАР 3",
        price: 0,
        quantity: 0,
        imgURL: "",
        category: {
          path: "piece-goods",
        },
      },
      {
        id: "piece-goods-4",
        name: "ТОВАР 4",
        price: 0,
        quantity: 0,
        imgURL: "",
        category: {
          path: "piece-goods",
        },
      },
    ],
  },
  reducers: {
    setPrice: (state, action) => {
      const targetProduct = state.products.find((product) => product.id === action.payload.id);
      if (!targetProduct) return;
      targetProduct.price = action.payload.price;
      state.products = state.products.map((product) => {
        return product.id === targetProduct.id ? targetProduct : product;
      });
    },
    setWeight: (state, action) => {
      const targetProduct = state.products.find((product) => product.id === action.payload.id);
      if (!targetProduct) return;
      targetProduct.weight = action.payload.weight;

      state.products = state.products.map((product) => {
        return product.id === targetProduct.id ? targetProduct : product;
      });
    },
    setQuantity: (state, action) => {
      const targetProduct = state.products.find((product) => product.id === action.payload.id);
      if (!targetProduct) return;
      targetProduct.quantity = action.payload.quantity;

      state.products = state.products.map((product) => {
        return product.id === targetProduct.id ? targetProduct : product;
      });
    },
    clearProductProperties: (state, action) => {
      const targetProduct = state.products.find((product) => product.id === action.payload.id);
      if (!targetProduct) return;
      targetProduct.weight = 0;
      targetProduct.price = 0;
      targetProduct.quantity = 0;

      state.products = state.products.map((product) => {
        return product.id === targetProduct.id ? targetProduct : product;
      });
    },
  },
});

export const { setPrice, setWeight, setQuantity, clearProductProperties } = productsSlice.actions;

export default productsSlice.reducer;

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ClearButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.primary.dark,
  // backgroundColor: theme.palette.grey[100],
  // padding: 0,
  // width: '36px',
  // height: '36px',
  fontSize: "14px",
  paddingTop: "90%",
  paddingBottom: "90%",
  backgroundColor: "#757585",
}));

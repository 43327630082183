import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export const Categories = () => {
  const navigate = useNavigate();

  const goToStart = () => {
    navigate("/");
  };
  const goToVegetables = () => {
    navigate("/categories/vegetables");
  };

  const goToFruits = () => {
    navigate("/categories/fruits");
  };

  const goToPieceGoods = () => {
    navigate("/categories/piece-goods");
  };

  const goToForWeighing = () => {
    navigate("/for-weighing");
  };

  const goToPurchasedGoods = () => {
    navigate("/purchased-goods");
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box height="90%">
          <Stack spacing={1}>
            <Button onClick={goToVegetables}>ОВОЩИ</Button>
            <Button onClick={goToFruits}>ФРУКТЫ</Button>
            <Button onClick={goToPieceGoods}>ШТУЧНЫЕ ПРОДУКТЫ</Button>
            <Button onClick={goToForWeighing}>ВЗВЕШИВАНИЕ</Button>
            <Button onClick={goToPurchasedGoods}>КУПЛЕННЫЕ ПРОДУКТЫ</Button>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Button onClick={goToStart}>НАЗАД</Button>
          </Stack>
        </Box>
      </Container>
    </>
  );
};
